import { Add, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import * as yup from 'yup';

import {
  Country,
  Disease,
  DiseaseStatusText,
  EDiseaseStatus,
  ENextRound,
  ENextRoundVariant,
  ETypePets,
  ETypeVaccine,
  EUserRole,
  TDiseaseCountry,
  TDiseaseRound,
} from 'shared/api';
import { AutocompleteCheckboxes, AutocompleteCountry, SelectInput } from 'shared/ui';

const validationSchema = yup.object({
  name: yup.string().required('Болезнь обязательное поле'),
  code: yup.string().required('Код болезни обязательное поле'),
  vaccine_list: yup.array().of(yup.string()),
  countries: yup.array().of(
    yup.object().shape({
      status: yup
        .mixed<EDiseaseStatus>()
        .oneOf(Object.values(EDiseaseStatus))
        .required('Статус для страны обязательное поле'),
    }),
  ),
  descriptions: yup.string(),
  descriptions_en: yup.string(),
});

const initialRound: TDiseaseRound = {
  type_round: ENextRoundVariant.AnyDays,
  type_vaccination: ETypeVaccine.Vaccination,
  count_days: undefined,
  count_days2: undefined,
  count_years: undefined,
  isPeriodicRevactination: false,
};

const initialValues: Omit<Disease, '_id'> = {
  name: '',
  name_ru: '',
  code: '',
  vaccine_list: [],
  verified: false,
  isIncludePastDiseases: false,
  descriptions: undefined as unknown as string,
  descriptions_en: undefined as unknown as string,
  notes: undefined as unknown as string,
  countries: [],
};

const createCountrySettings = (countryCode: string) => ({
  status: EDiseaseStatus.Basic,
  countryCode,
  age_shedule: [
    {
      start: 0,
      end: 36500,
      rounds: [initialRound],
    },
  ],
  completed: false,
});

type Props = {
  title: string;
  isOpen: boolean;
  isPets?: boolean;
  vaccineList: Array<{
    label: string;
    value: string;
    labelContext?: string;
    manufacturerCountry: string;
    manufacturer: string;
  }>;
  diseaseList: Disease[];
  selectedDisease?: Disease;
  countriesList: Country[];
  isLoading: boolean;
  userRole?: EUserRole;
  onVerified?: Function;
  onSubmit: Function;
  onClose: () => void;
};

export const DiseaseDialog = ({
  title,
  isOpen,
  isPets = false,
  vaccineList,
  diseaseList,
  selectedDisease,
  countriesList,
  isLoading,
  userRole,
  onVerified,
  onClose,
  onSubmit,
}: Props) => {
  const [selectedAgeShedule, setSelectedAgeShedule] = useState(0);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [selectedRound, setSelectedRound] = useState(0);
  const [isOpenCopyDialog, setIsOpenCopyDialog] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: Omit<Disease, '_id'>) => {
      const newCountries = values.countries.map((item) => {
        const newAgeShedules = item.age_shedule.map((shedule) => {
          return {
            ...shedule,
            rounds: shedule.rounds.map((round) => {
              if (round.type_vaccination === ETypeVaccine.ReVaccination && round.isPeriodicRevactination) {
                return {
                  ...round,
                  count_days: undefined,
                  count_days2: undefined,
                };
              }

              if (round.type_vaccination === ETypeVaccine.Vaccination) {
                return {
                  ...round,
                  count_years: undefined,
                  isPeriodicRevactination: false,
                };
              }

              return round;
            }),
          };
        });

        return { ...item, age_shedule: newAgeShedules };
      });
      onSubmit({ ...values, countries: newCountries });
    },
  });

  const getSelectedCountryValue = () =>
    formik.values.countries.find(({ countryCode }) => countryCode === selectedCountryCode);

  const isExistDiseaseName =
    !!diseaseList.find(({ name }) => name === formik.values.name) && formik.values.name !== selectedDisease?.name;

  const handleAddRound = () => {
    const selectedCountry = getSelectedCountryValue();

    if (!selectedCountry) return;

    const newData = formik.values.countries.map((item) =>
      item.countryCode === selectedCountryCode
        ? {
            ...item,
            age_shedule: item.age_shedule.map((shedule, index) =>
              selectedAgeShedule === index ? { ...shedule, rounds: [...shedule.rounds, initialRound] } : shedule,
            ),
          }
        : item,
    );

    void formik.setFieldValue('countries', newData);
    setSelectedRound(selectedCountry.age_shedule[selectedAgeShedule].rounds.length);
  };

  const handleDeleteRound = () => {
    const newData = formik.values.countries.map((item) => {
      if (item.countryCode === selectedCountryCode) {
        const newData = {
          ...item,
          age_shedule: item.age_shedule.map((shedule, index) => {
            const rounds = [...shedule.rounds];
            rounds.pop();
            return selectedAgeShedule === index ? { ...shedule, rounds } : shedule;
          }),
        };

        return newData;
      }

      return item;
    });
    setSelectedRound((prev) => --prev);
    void formik.setFieldValue('countries', newData);
  };

  const handleChangeCountry = (_: any, v: any) => setSelectedCountryCode(v?.code ?? '');

  const handleChangeCountryData = (fieldName: string, value: any) => {
    const isExistCountryData = getSelectedCountryValue();

    if (isExistCountryData) {
      const list = formik.values.countries.map((item) => {
        if (fieldName === 'completed' || fieldName === 'status') {
          return item.countryCode === selectedCountryCode ? { ...item, [fieldName]: value } : item;
        }

        return item.countryCode === selectedCountryCode
          ? {
              ...item,
              age_shedule: item.age_shedule.map((shedule, sheduleIndex) => {
                if (fieldName === 'start' || fieldName === 'end') {
                  return selectedAgeShedule === sheduleIndex ? { ...shedule, [fieldName]: value } : shedule;
                } else {
                  const rounds = shedule.rounds.map((round, index) =>
                    selectedRound === index ? { ...round, [fieldName]: value } : round,
                  );

                  const newShedule = {
                    ...shedule,
                    rounds,
                  };

                  return selectedAgeShedule === sheduleIndex ? newShedule : shedule;
                }
              }),
            }
          : item;
      });

      void formik.setFieldValue('countries', list);
      return;
    }

    // создаем новый блок для страны если еще не было добавлено никаких данных
    const newCountryData = createCountrySettings(selectedCountryCode);

    void formik.setFieldValue('countries', [...formik.values.countries, { ...newCountryData, [fieldName]: value }]);
  };

  const handleClearCountrySettings = () => {
    const newCountries = formik.values.countries.filter(({ countryCode }) => countryCode !== selectedCountryCode);

    void formik.setFieldValue('countries', newCountries);
  };

  const handleToggleCompletedCountrySettings = () => {
    const completed = getSelectedCountryValue()?.completed;
    handleChangeCountryData('completed', !completed);
  };

  const handleOpenCopyCountrySettings = () => setIsOpenCopyDialog(true);

  const handleCopyCoutrySettings = (settings: TDiseaseCountry | undefined) => {
    if (!settings) return;

    const isExist = formik.values.countries.find((item) => item.countryCode === settings?.countryCode);

    if (isExist) {
      void formik.setFieldValue('countries', [
        ...formik.values.countries.filter((item) => item.countryCode !== settings.countryCode),
        settings,
      ]);
    } else {
      void formik.setFieldValue('countries', [...formik.values.countries, settings]);
    }
  };

  const handleAddAgeShedule = () => {
    const currentCountry = getSelectedCountryValue();

    if (currentCountry) {
      const lastPeriod = currentCountry.age_shedule.at(-1);

      void formik.setFieldValue('countries', [
        ...formik.values.countries.filter((item) => item.countryCode !== selectedCountryCode),
        {
          ...currentCountry,
          age_shedule: [
            ...currentCountry.age_shedule,
            {
              start: lastPeriod?.end ? +lastPeriod?.end + 1 : '0',
              end: lastPeriod?.end ? +lastPeriod?.end + 2 : '0',
              rounds: [initialRound],
            },
          ],
        },
      ]);
      setSelectedAgeShedule(selectedAgeShedule + 1);
    } else {
      const newCountryData = createCountrySettings(selectedCountryCode);
      void formik.setFieldValue('countries', [...formik.values.countries, newCountryData]);
      setSelectedAgeShedule(0);
    }
  };

  const handleDeleteAgeShedule = () => {
    const currentCountry = getSelectedCountryValue();

    if (currentCountry) {
      void formik.setFieldValue('countries', [
        ...formik.values.countries.filter((item) => item.countryCode !== selectedCountryCode),
        {
          ...currentCountry,
          age_shedule: currentCountry.age_shedule.slice(0, -1),
        },
      ]);
    }

    setSelectedAgeShedule(selectedAgeShedule - 1);
  };

  useEffect(() => {
    if (isOpen && selectedDisease) void formik.setValues(selectedDisease);

    return () => {
      void formik.setValues(initialValues);
      setSelectedCountryCode('');
    };
  }, [isOpen]);

  useEffect(() => setSelectedRound(0), [selectedCountryCode, selectedAgeShedule]);

  return (
    <Fragment>
      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <TextField
              label="Name (EN)"
              fullWidth
              margin="dense"
              name="name"
              value={formik.values.name ?? selectedDisease?.name}
              onChange={formik.handleChange}
              error={isExistDiseaseName}
              helperText={isExistDiseaseName && 'Такая болезнь уже добавлена!'}
            />
            <TextField
              label="Name (RU)"
              fullWidth
              margin="dense"
              name="name_ru"
              value={formik.values.name_ru ?? selectedDisease?.name_ru}
              onChange={formik.handleChange}
              error={isExistDiseaseName}
              helperText={isExistDiseaseName && 'Такая болезнь уже добавлена!'}
            />
            <TextField
              label="Code (EN)"
              fullWidth
              margin="dense"
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
            />
            {isPets && (
              <FormControl fullWidth margin="dense">
                <InputLabel>Type</InputLabel>
                <Select
                  label="Type"
                  value={formik.values.type_pets}
                  defaultValue={selectedDisease?.type_pets}
                  name="type_pets"
                  onChange={formik.handleChange}
                >
                  {Object.entries(ETypePets).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <AutocompleteCheckboxes
              label="Suitable vaccines"
              multiple={true}
              name="vaccine_list"
              options={vaccineList}
              values={formik.values.vaccine_list}
              onChange={formik.setFieldValue}
              error={formik.touched.vaccine_list && Boolean(formik.errors.vaccine_list)}
              helperText={formik.touched.vaccine_list && formik.errors.vaccine_list}
            />
            {formik.values.countries.filter((item) => item.status === EDiseaseStatus.Basic).length > 0 && (
              <Autocomplete
                fullWidth
                readOnly
                multiple
                value={formik.values.countries
                  .filter((item) => item.status === EDiseaseStatus.Basic)
                  .map(({ countryCode }) => countriesList.find((item) => item.code === countryCode))}
                options={formik.values.countries
                  .filter((item) => item.status === EDiseaseStatus.Basic)
                  .map(({ countryCode }) => countriesList.find((item) => item.code === countryCode))}
                renderInput={(params) => {
                  return <TextField {...params} margin="dense" label="Basic vaccination calendar of countries" />;
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={`${index}`}
                      label={option?.name}
                      sx={{
                        color: formik.values.countries.find((item) => item.countryCode === option?.code)?.completed
                          ? '#29b194'
                          : '',
                      }}
                    />
                  ))
                }
                sx={{
                  '& .MuiAutocomplete-endAdornment': {
                    display: 'none',
                  },
                }}
              />
            )}
            {formik.values.countries.filter((item) => item.status === EDiseaseStatus.Mandatory).length > 0 && (
              <Autocomplete
                fullWidth
                readOnly
                multiple
                value={formik.values.countries
                  .filter((item) => item.status === EDiseaseStatus.Mandatory)
                  .map(({ countryCode }) => countriesList.find((item) => item.code === countryCode))}
                options={formik.values.countries
                  .filter((item) => item.status === EDiseaseStatus.Mandatory)
                  .map(({ countryCode }) => countriesList.find((item) => item.code === countryCode))}
                renderInput={(params) => {
                  return (
                    <TextField {...params} margin="dense" label="In the national vaccination calendar of countries" />
                  );
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={`${index}`}
                      label={option?.name}
                      sx={{
                        color: formik.values.countries.find((item) => item.countryCode === option?.code)?.completed
                          ? '#29b194'
                          : '',
                      }}
                    />
                  ))
                }
                sx={{
                  '& .MuiAutocomplete-endAdornment': {
                    display: 'none',
                  },
                }}
              />
            )}
            {formik.values.countries.filter((item) => item.status === EDiseaseStatus.Additional).length > 0 && (
              <Autocomplete
                fullWidth
                readOnly
                multiple
                value={formik.values.countries
                  .filter((item) => item.status === EDiseaseStatus.Additional)
                  .map(({ countryCode }) => countriesList.find((item) => item.code === countryCode))}
                options={formik.values.countries
                  .filter((item) => item.status === EDiseaseStatus.Additional)
                  .map(({ countryCode }) => countriesList.find((item) => item.code === countryCode))}
                renderInput={(params) => {
                  return <TextField {...params} margin="dense" label="Additional vaccination calendar of countries" />;
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={`${index}`}
                      label={option?.name}
                      sx={{
                        color: formik.values.countries.find((item) => item.countryCode === option?.code)?.completed
                          ? '#29b194'
                          : '',
                      }}
                    />
                  ))
                }
                sx={{
                  '& .MuiAutocomplete-endAdornment': {
                    display: 'none',
                  },
                }}
              />
            )}
            {formik.values.countries.filter((item) => item.status === EDiseaseStatus.Epidemic).length > 0 && (
              <Autocomplete
                fullWidth
                readOnly
                multiple
                value={formik.values.countries
                  .filter((item) => item.status === EDiseaseStatus.Epidemic)
                  .map(({ countryCode }) => countriesList.find((item) => item.code === countryCode))}
                options={formik.values.countries
                  .filter((item) => item.status === EDiseaseStatus.Epidemic)
                  .map(({ countryCode }) => countriesList.find((item) => item.code === countryCode))}
                renderInput={(params) => {
                  return <TextField {...params} margin="dense" label="Epidemic vaccination calendar of countries" />;
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={`${index}`}
                      label={option?.name}
                      sx={{
                        color: formik.values.countries.find((item) => item.countryCode === option?.code)?.completed
                          ? '#29b194'
                          : '',
                      }}
                    />
                  ))
                }
                sx={{
                  '& .MuiAutocomplete-endAdornment': {
                    display: 'none',
                  },
                }}
              />
            )}

            <Box sx={{ backgroundColor: 'rgba(41,177,148, 0.15)' }} p={2}>
              <Typography variant="body2" fontWeight="bold" mb={1}>
                Round for country
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <AutocompleteCountry
                  label="Country"
                  value={selectedCountryCode}
                  countries={countriesList}
                  compareList={formik.values.countries}
                  onChange={handleChangeCountry}
                  sx={{ width: '73%' }}
                />
                <Button
                  variant="contained"
                  color={!getSelectedCountryValue()?.completed ? 'primary' : 'error'}
                  sx={{ width: '25%' }}
                  onClick={handleToggleCompletedCountrySettings}
                  disabled={!getSelectedCountryValue()?.status}
                >
                  {!getSelectedCountryValue()?.completed ? 'Completed' : 'Uncompleted'}
                </Button>
              </Box>
              {selectedCountryCode && (
                <Box mt={1}>
                  <Box display="flex" justifyContent="space-between" mb={1}>
                    <Button variant="text" color="primary" size="small" onClick={handleOpenCopyCountrySettings}>
                      Copy from
                    </Button>
                    {getSelectedCountryValue() && (
                      <Button
                        variant="outlined"
                        startIcon={<Close />}
                        color="error"
                        size="small"
                        onClick={handleClearCountrySettings}
                      >
                        Delete setting country
                      </Button>
                    )}
                  </Box>
                  {getSelectedCountryValue() && (
                    <FormControl
                      fullWidth
                      margin="dense"
                      sx={{ marginBottom: '16px' }}
                      error={!getSelectedCountryValue()?.status}
                    >
                      <InputLabel>Status*</InputLabel>
                      <Select
                        label="Status*"
                        value={getSelectedCountryValue()?.status}
                        name={'status'}
                        onChange={(e: any) => handleChangeCountryData('status', e.target.value)}
                      >
                        {Object.values(EDiseaseStatus).map((status) => (
                          <MenuItem key={status} value={status}>
                            {DiseaseStatusText[status]}
                          </MenuItem>
                        ))}
                      </Select>
                      {!getSelectedCountryValue()?.status && <FormHelperText>Required field</FormHelperText>}
                    </FormControl>
                  )}
                  <Box>
                    <Typography fontWeight="bold">Age Shedule</Typography>
                    <Box display="flex" gap={1} alignItems="center" flexWrap={'wrap'} my={1}>
                      {getSelectedCountryValue()?.age_shedule.map((item, index) => (
                        <Chip
                          key={`${index} + ${item.start}age_shedule`}
                          label={`${item.start}-${item.end}`}
                          onClick={() => setSelectedAgeShedule(index)}
                          sx={{
                            backgroundColor: selectedAgeShedule === index ? '#29b194' : 'unset',
                            color: selectedAgeShedule === index ? 'white' : '',
                            borderRadius: '6px',
                            border: selectedAgeShedule !== index ? '1px solid rgba(0, 0, 0, 0.3)' : '',
                            margin: '0 2px',
                          }}
                          clickable={selectedAgeShedule !== index}
                        />
                      ))}
                      <Box display="flex" gap={1} alignItems="center">
                        {getSelectedCountryValue()?.age_shedule?.length === 0 && (
                          <Typography>Add first shedule</Typography>
                        )}
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            ml: 1,
                            px: 0.5,
                            minWidth: 'auto',
                            color: 'rgba(58, 58, 58, 1)',
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            ':hover': { backgroundColor: 'rgba(0, 0, 0, 0.07)' },
                          }}
                          onClick={handleAddAgeShedule}
                        >
                          <Add />
                        </Button>
                      </Box>
                    </Box>
                    {(getSelectedCountryValue()?.age_shedule?.length ?? 0) > 0 && (
                      <Box mb={2}>
                        <Box display="flex" justifyContent="space-between">
                          <TextField
                            sx={{ width: '49%' }}
                            margin="dense"
                            label="Start (in days)*"
                            type="number"
                            name={`start`}
                            value={getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.start}
                            onChange={(e: any) => handleChangeCountryData('start', e.target.value)}
                            error={
                              `${getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.start ?? ''}`.length === 0
                            }
                            helperText={
                              `${getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.start ?? ''}`.length ===
                                0 && 'Field is required!'
                            }
                          />
                          <TextField
                            sx={{ width: '49%' }}
                            margin="dense"
                            label="End (in days)*"
                            type="number"
                            name={`end`}
                            value={getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.end}
                            onChange={(e: any) => handleChangeCountryData('end', e.target.value)}
                            error={
                              `${getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.end ?? ''}`.length === 0
                            }
                            helperText={
                              `${getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.end ?? ''}`.length === 0 &&
                              'Field is required!'
                            }
                          />
                        </Box>
                        {selectedAgeShedule !== 0 && (
                          <Box display="flex" justifyContent="flex-end">
                            <Button color="error" size="small" onClick={handleDeleteAgeShedule}>
                              DELETE THIS AGE
                            </Button>
                          </Box>
                        )}
                      </Box>
                    )}
                    {getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.rounds.map((round, index) => (
                      <Chip
                        key={index}
                        label={`Round ${index + 1}`}
                        onClick={() => setSelectedRound(index)}
                        sx={{
                          backgroundColor: selectedRound === index ? '#29b194' : 'unset',
                          color: selectedRound === index ? 'white' : '',
                          borderRadius: '6px',
                          border: selectedRound !== index ? '1px solid rgba(0, 0, 0, 0.3)' : '',
                          margin: '0 2px',
                        }}
                        clickable={selectedRound !== index}
                      />
                    ))}
                    {!(
                      getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.rounds.at(-1)?.type_vaccination ===
                        ETypeVaccine.ReVaccination &&
                      getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.rounds.at(-1)?.isPeriodicRevactination
                    ) &&
                      !((getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.rounds?.length ?? 0) === 0) && (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            ml: 1,
                            px: 0.5,
                            minWidth: 'auto',
                            color: 'rgba(58, 58, 58, 1)',
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            ':hover': { backgroundColor: 'rgba(0, 0, 0, 0.07)' },
                          }}
                          onClick={handleAddRound}
                        >
                          <Add />
                        </Button>
                      )}
                    {getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.rounds[selectedRound] &&
                      [getSelectedCountryValue()?.age_shedule[selectedAgeShedule]?.rounds[selectedRound]].map(
                        (round) => (
                          <Box key={`${selectedAgeShedule}_${selectedRound}_age_shedule_rounds`} mt={2}>
                            <RadioGroup
                              row
                              aria-labelledby="type_vaccination"
                              name={`age_shedule[${selectedAgeShedule}].rounds[${selectedRound}].type_vaccination`}
                              value={
                                getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                                  ?.type_vaccination
                              }
                              onChange={(e: any) => handleChangeCountryData('type_vaccination', e.target.value)}
                            >
                              <FormControlLabel
                                value={ETypeVaccine.Vaccination}
                                control={<Radio />}
                                label="Vaccination"
                              />
                              <FormControlLabel
                                value={ETypeVaccine.ReVaccination}
                                control={<Radio />}
                                label="Revaccination"
                              />
                            </RadioGroup>
                            {getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                              ?.type_vaccination === ETypeVaccine.ReVaccination && (
                              <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="Periodically"
                                name={`age_shedule[${selectedAgeShedule}].rounds[${selectedRound}].isPeriodicRevactination`}
                                checked={
                                  getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                                    ?.isPeriodicRevactination
                                }
                                onChange={(e: any) =>
                                  handleChangeCountryData(
                                    'isPeriodicRevactination',
                                    !getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                                      ?.isPeriodicRevactination,
                                  )
                                }
                              />
                            )}
                            <SelectInput
                              label="Type round (date vaccination)"
                              name={`type_round`}
                              value={
                                getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                                  ?.type_round
                              }
                              menuItem={Object.entries(ENextRound)
                                .filter((_, index) => {
                                  if (selectedRound === 0 && index < 2) return true;
                                  if (selectedRound === 1 && index > 0) return true;
                                  if (selectedRound > 1 && index > 0) return true;

                                  return false;
                                })
                                .map(([value, label]) => ({ label, value }))}
                              onChange={(_: any, value: any) => handleChangeCountryData('type_round', value)}
                            />
                            {(getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                              ?.type_vaccination === ETypeVaccine.Vaccination ||
                              (getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                                ?.type_vaccination === ETypeVaccine.ReVaccination &&
                                !getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                                  ?.isPeriodicRevactination)) &&
                              getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                                ?.type_round !== ENextRoundVariant.AnyDays && (
                                <Box display="flex" justifyContent="space-between">
                                  <TextField
                                    sx={{ width: '49%' }}
                                    margin="dense"
                                    label="Start (in days)"
                                    type="number"
                                    name={`age_shedule[${selectedAgeShedule}].rounds[${selectedRound}].count_days`}
                                    value={
                                      getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                                        ?.count_days
                                    }
                                    onChange={(e: any) => handleChangeCountryData('count_days', e.target.value)}
                                  />
                                  <TextField
                                    sx={{ width: '49%' }}
                                    margin="dense"
                                    label="End (in days)"
                                    type="number"
                                    name={`age_shedule[${selectedAgeShedule}].rounds[${selectedRound}].count_days2`}
                                    value={
                                      getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                                        ?.count_days2
                                    }
                                    onChange={(e: any) => handleChangeCountryData('count_days2', e.target.value)}
                                  />
                                </Box>
                              )}
                            {getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                              ?.type_vaccination === ETypeVaccine.ReVaccination &&
                              getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                                ?.isPeriodicRevactination && (
                                <FormControl fullWidth margin="dense">
                                  <InputLabel>Period</InputLabel>
                                  <Select
                                    label="Age"
                                    value={
                                      getSelectedCountryValue()?.age_shedule[selectedAgeShedule].rounds[selectedRound]
                                        ?.count_years
                                    }
                                    name={`age_shedule[${selectedAgeShedule}].rounds[${selectedRound}].count_years`}
                                    onChange={(e: any) => handleChangeCountryData('count_years', e.target.value)}
                                  >
                                    <MenuItem value={'3M'}>Once every 3 month</MenuItem>
                                    <MenuItem value={'6M'}>Once every 6 month</MenuItem>
                                    <MenuItem value={'1y'}>Every year</MenuItem>
                                    <MenuItem value={'2y'}>Once every 2 years</MenuItem>
                                    <MenuItem value={'3y'}>Once every 3 years</MenuItem>
                                    <MenuItem value={'5y'}>Once every 5 years</MenuItem>
                                    <MenuItem value={'10y'}>Once every 10 years</MenuItem>
                                    <MenuItem value={'15y'}>Once every 15 years</MenuItem>
                                    <MenuItem value={'20y'}>Once every 20 years</MenuItem>
                                    <MenuItem value={'25y'}>Once every 25 years</MenuItem>
                                    <MenuItem value={'30y'}>Once every 30 years</MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                            {selectedRound !== 0 && (
                              <Box display="flex" justifyContent="flex-end">
                                <Button color="error" size="small" onClick={handleDeleteRound}>
                                  DELETE THIS ROUND
                                </Button>
                              </Box>
                            )}
                          </Box>
                        ),
                      )}
                  </Box>
                </Box>
              )}
            </Box>
            {/* <FormControlLabel
              label="Входит в список при выборе ранее перенесенных"
              control={<Checkbox />}
              name={`isIncludePastDiseases`}
              checked={formik.values.isIncludePastDiseases ?? selectedDisease?.isIncludePastDiseases}
              onChange={formik.handleChange}
            /> */}
            <TextField
              label="Descriptions (EN)"
              fullWidth
              sx={{ mt: 4 }}
              margin="dense"
              multiline
              maxRows={6}
              name="descriptions_en"
              value={formik.values.descriptions_en ?? selectedDisease?.descriptions_en}
              onChange={formik.handleChange}
            />
            <TextField
              label="Descriptions (RU)"
              fullWidth
              margin="dense"
              multiline
              maxRows={6}
              name="descriptions"
              value={formik.values.descriptions ?? selectedDisease?.descriptions}
              onChange={formik.handleChange}
            />
            <TextField
              label="Notes"
              fullWidth
              margin="dense"
              maxRows={6}
              multiline
              name="notes"
              value={formik.values.notes ?? selectedDisease?.notes}
              onChange={formik.handleChange}
            />
            {formik.touched.name && Boolean(formik.errors.name) && (
              <Typography color="error">{formik.errors.name as string}</Typography>
            )}
          </DialogContent>
          {formik.values.countries.map((item) =>
            !item.status ? (
              <Typography key={item.countryCode} color="red" textAlign="center">
                В {countriesList.find(({ code }) => code === item.countryCode)?.name} статус обязательное поле!
              </Typography>
            ) : null,
          )}
          <DialogActions>
            {userRole === EUserRole.Admin && onVerified && (
              <Button
                onClick={() => {
                  onVerified({ ...formik.values, verified: !formik.values.verified });
                  onClose();
                }}
                variant="contained"
                disabled={isExistDiseaseName || !formik.isValid}
                color={formik.values.verified ? 'error' : 'primary'}
                sx={{ mr: 'auto' }}
              >
                {formik.values.verified ? 'Unverified' : 'Save and Verified'}
              </Button>
            )}
            <Button onClick={onClose} sx={{ mr: 8 }}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              disabled={isExistDiseaseName || !formik.isValid}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <CopyCountrySettingsDialog
        isOpen={isOpenCopyDialog}
        handleClose={() => setIsOpenCopyDialog(false)}
        handleCopy={handleCopyCoutrySettings}
        selectedCountryCode={selectedCountryCode}
        countriesList={countriesList}
        countries={formik.values.countries}
      />
    </Fragment>
  );
};

type CopyCountryProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleCopy: (settings: TDiseaseCountry | undefined) => void;
  selectedCountryCode: string;
  countriesList: Country[];
  countries: TDiseaseCountry[];
};

const CopyCountrySettingsDialog = ({
  isOpen,
  selectedCountryCode,
  countriesList,
  countries,
  handleClose,
  handleCopy,
}: CopyCountryProps) => {
  const [code, setCode] = useState('');

  const countryList = countriesList
    ?.filter((item) => countries?.find(({ countryCode }) => item.code === countryCode))
    ?.filter((item) => item.code !== selectedCountryCode);

  const targetSettings = (): TDiseaseCountry | undefined => {
    const target = countries?.find(({ countryCode }) => code === countryCode);

    if (target)
      return {
        ...target,
        countryCode: selectedCountryCode,
      };
  };

  return (
    <Fragment>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Copy country settings</DialogTitle>
        <DialogContent>
          <AutocompleteCountry
            label="Country"
            fullWidth
            value={code}
            countries={countryList}
            onChange={(_, value) => setCode(value.code)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              handleCopy(targetSettings());
              handleClose();
            }}
          >
            Copy
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
